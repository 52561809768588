// This file holds "utility" classes that can be used
// anywhere.

.hide {
  display: none;
}

.full-width {
  width: 100%;
}

.flexible-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.full-height {
  height: 100%;
}

.mt-auto {
  margin-top: auto;
}

.calories-color {
  color: var(--ion-1p-calorie-color);
}

.protein-color {
  color: var(--ion-1p-protein-color);
}

.carbs-color {
  color: var(--ion-1p-carb-color);
}

.fat-color {
  color: var(--ion-1p-fat-color);
}

.fiber-color {
  color: var(--ion-color-medium);
}

$increment: 5;

@while $increment <=30 {
  .global-margin-top-#{$increment} {
    margin-top: #{$increment}px;
  }

  $increment: $increment + 5;
}

.device-padding {
  --padding-top: var(--ion-safe-area-top);
  --padding-bottom: var(--ion-safe-area-bottom);
}

.device-padding-bottom {
  --padding-bottom: var(--ion-safe-area-bottom);
}

.indication {
  position: absolute;
  width: 0.5em;
  height: 0.5em;
  display: block;
  bottom: 0;
  background-color: var(--ion-color-base);
  border-radius: 50%;
  right: -2px;
}

@mixin custom-feed-input {
  height: 35px;
  line-height: 29px;
  font-weight: 500;
  border-radius: 40px;
  border: 2px solid var(--ion-1p-white-gray-lightest);
  margin: 0;
  outline: none;
  font-size: calc(var(--default-font-size) * 0.93);
}
