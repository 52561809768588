@import 'ion-alert';
@import 'fue-modal';
@import 'recommended-age-modal';
@import 'header-title';

html {
  background-color: transparent;
}

ion-app {
  --bottom-part: 0px;

  &.with-bottom-menu {
    --bottom-part: var(--app-bottom-menu-height);
  }
}

ion-toast {
  --border-radius: 4px;
  font-weight: 600;
  --background: var(--ion-1p-white-background);
  --color: var(--ion-color-light-contrast);

  height: calc(100% - var(--bottom-part));
  &#logging-in {
    --width: fit-content;
    .toast-container {
      justify-content: center;
      display: flex;
    }
  }
}

.ios .loading-wrapper {
  border-radius: 0;
}

.header-image {
  max-height: 25vh;
  overflow-y: hidden;
  display: flex;

  img {
    width: 100vw;
    display: block;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.grid-padding-none {
  --ion-grid-padding: 0;
}

app-five-star-rating {
  font-size: 16pt;
  color: var(--ion-1p-blue-primary);
}

ion-app {
  &.with-bottom-menu ion-router-outlet {
    height: calc(100% - var(--app-bottom-menu-height, 75px));
  }

  app-bottom-nav {
    position: relative;
    top: calc(100% - var(--app-bottom-menu-height, 75px));
  }
}

p + p {
  margin-top: 0;
}

.empty-state {
  margin-top: calc(var(--default-margin) * 2);
  color: var(--ion-color-text-secondary);
  //margin-bottom: 0;
  img {
    width: 64px;
  }
}

.go-premium-css .modal-wrapper {
  width: 100%;
  height: 100%;
}

#message-entry .sc-ion-textarea-md-h {
  margin-top: 0;
  padding-top: 0;
}

.picker-unit .picker-wrapper {
  .picker-columns {
    width: 200px;
    margin: auto;

    .picker-col:nth-child(4) {
      padding-left: 0;
      margin-left: -26px;

      .picker-opt {
        text-align: left;
      }
    }
  }
}

ion-segment-button {
  --border-radius: 2px;
  --border-width: 2px;

  &.segment-button-has-label {
    ion-label {
      font-size: 11pt;
      font-weight: 600;
    }
  }

  &.segment-button-checked {
    + ion-segment-button {
      border-left-width: 0;
    }
  }

  &:first-of-type {
    border-right-width: 0;

    &.segment-button-checked {
      border-right-width: var(--border-width);
    }
  }

  &:not(:first-of-type) {
    border-left-width: 2px;

    &.segment-button-checked {
      border-left-width: var(--border-width);
    }
  }
}

ion-grid.fixed-padding {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;

  ion-col:first-child {
    padding-left: 0;
  }

  ion-col:last-child {
    padding-right: 0;
  }
}

.popover-viewport.sc-ion-popover-ios {
  --ion-safe-area-top: revert;
  min-height: calc(100vh - var(--app-bottom-menu-height));
}

section.card {
  .header {
    font-weight: bold;
    display: flex;
    text-transform: uppercase;
    justify-content: space-between;
  }

  &.rounded {
    border-radius: 4px;
  }
}

.dim {
  color: var(--ion-color-light-gray-blue);
}

.divider {
  border-bottom: 8px solid var(--ion-background-color);
}

.spinner-container {
  z-index: 9;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: var(--default-margin);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-1p-white-background);
}

.flex {
  display: flex;

  > * {
    width: 100%;
  }
}

ion-chip[size='xsmall'] {
  font-size: var(--ion-1p-font-size-caption);
  padding: 0.25em 0.75em 0.25em 1em;
  margin: 0;
  min-height: 1em;

  ion-icon {
    font-size: inherit;
  }
}

.sorta-padded {
  --ion-padding: 11px;
}

p.info {
  font-size: var(--app-small-paragraph-size);
}

ion-input,
ion-textarea,
ion-select {
  --padding-end: var(--default-margin) !important;
  --padding-start: var(--default-margin) !important;
  border: 1px solid var(--ion-color-medium-tint);
  border-radius: 4px;
  &:not(ion-textarea) {
    height: 48px;
  }
  margin-top: calc(var(--default-margin) / 2);

  &.has-focus {
    border-color: var(--ion-color-primary);
  }

  &.has-value.ion-invalid:not(.has-focus) {
    border-color: var(--ion-color-danger);
  }
}

@keyframes myAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  80% {
    transform: translateY(-7px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.left-half,
.right-half {
  height: 100%;
  width: 50vw;
  vertical-align: top;
  display: flex;
  flex-direction: column;
}

.my-windows {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;

  .full {
    width: 100vw;
  }

  .left-half {
    ion-router-outlet {
      height: 93%;
      width: 50vw;
    }
  }
  .right-half {
    ion-router-outlet {
      height: 100%;
      width: 50vw;
    }
  }

  .right-half {
    top: 0;
    left: 50vw;
    position: fixed;

    ion-router-outlet {
      ion-content {
        height: calc(100% - 55px);
      }
    }
  }

  @media screen and (max-width: 800px) {
    .left-side {
      ion-router-outlet.left-side {
        width: 100vw;
      }
    }
    .right-side {
      display: none;
    }
  }
}

.right-half .ion-page > *:not(router-outlet) {
  --app-bottom-menu-height: 30px;
  flex: 1;
}

ion-modal.subscriptionModal {
  --width: 100vw !important;
  --height: 100vh !important;
}

ion-modal.white-backdrop {
  --background: var(--ion-color-light);
}

.split {
  ion-router-outlet {
    width: 50%;
    left: 0;
  }
  #nav-menu-expanded {
    width: 50%;
  }
  ion-modal.split-pane {
    width: 50%;
    height: 100%;
    left: 50%;
    > div[role='dialog'] {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-left: 2px solid var(--ion-color-light);
      width: 100%;
      height: 100%;
      left: 50%;
    }
  }
}

.amount-selector ion-input {
  text-align: center;
}

ion-searchbar.v2 {
  flex: 12;
  border: none;
  height: 30px;
  margin: 10px 0;
  --box-shadow: none;
  border-radius: 30px;
  color: var(--ion-1p-gray) !important;
  --background: transparent !important;
  background: var(--ion-color-light);
  font-size: var(--default-font-size) !important;
}

.bottom-right-action {
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--ion-color-text-secondary);
  justify-content: flex-end;
  font-weight: 600;
  font-size: 85%;
  line-height: 1em;
  padding: 0.5em;
}

.ios {
  --app-toolbar-min-height: 44px;
}
.md {
  --app-toolbar-min-height: 56px;
}

.icon-indicated {
  position: relative;
}

//
body.scanner-active,
body.scanner-active #__dark_mode_wrapper__ {
  --background: transparent;
  --ion-background-color: transparent;

  ion-modal:not(.scanner-overlay),
  .my-windows {
    opacity: 0;
  }
}

.md .alert-input-group {
  padding-top: 0;
}

/**
 * Overrides the "cancel" button on an alert to have danger/red text.
 */
button.alert-button.alert-button-role-cancel {
  color: var(--ion-color-danger);
  font-weight: 500;
}

/**
 * Overrides for ion-card. Remove the box-shadow and adjust the background to match a contrast color.
 */
ion-card {
  box-shadow: none;
  --background: var(--ion-1p-white-background);
}

ion-card-title {
  font-family: 'Industry', sans-serif;
}
