ion-button {
  --border-width: 4px;
  --box-shadow: 0;
  --border-radius: 6px;
  --font-weight: bold;
  border-radius: var(--border-radius);
  font-weight: var(--font-weight);
  --letter-spacing: 1px;
  letter-spacing: var(--letter-spacing);
  --text-transform: uppercase;
  text-transform: var(--text-transform);
  overflow: hidden;
  height: var(--height, 48px);
  --height: 48px;

  &[shape='round'] {
    --border-radius: 100px;
    --padding-start: 0.75em;
    --padding-end: 0.75em;
    .button-native {
      padding-left: var(--padding-start);
      padding-right: var(--padding-end);
    }
  }

  &[fill='outline'] {
    --border-width: 4px;
    &.button-full .button-native {
      border-right-width: var(--border-width);
      border-left-width: var(--border-width);
    }
  }

  &[size='large'] {
    --padding-top: 15px;
    --padding-bottom: 15px;
  }
  &.centered {
    margin-left: auto;
    margin-right: auto;
  }
}

ion-button[expand='full'][fill='outline'] {
  border-color: var(--ion-color-base);
  border-right-style: solid;
  border-left-style: solid;
  border-right-width: var(--border-width);
  border-left-width: var(--border-width);
  --padding-end: 16px;
}

ion-button[fill='outline'] {
  --border-width: 2px;
  --border-radius: 6px;
}

// A completely round button. For an icon, for example.
ion-button[shape='round'] {
  --border-radius: var(--height);

  button.button-native {
    padding-inline-start: unset;
    padding-inline-end: unset;
  }
}
