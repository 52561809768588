.help-content {
  font-size: 1em;
  line-height: 1.5em;
  padding: var(--default-margin);

  section {
    background: var(--ion-background-color, #fff);
    border-bottom: 1px solid var(--ion-color-medium);
    padding: calc(var(--ion-padding, 16px) / 2) var(--ion-padding, 16px);
    margin-bottom: var(--ion-margin, 1px);
    &:last-child {
      padding-bottom: var(--ion-padding, 16px);
      margin-bottom: calc(var(--ion-padding, 16px) * 2);
    }
  }

  h1,
  h2 {
    margin: 1em 0 0.5em;
    &:first-child {
      margin-top: 0.5em;
    }
  }

  h1 {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.33em;
  }

  h2 {
    font-size: 1.1em;
    font-weight: 600;
  }

  p {
    font-size: 1em;
  }

  a {
    line-height: 1.5em;
    text-decoration: none;
    &.external {
    }
  }

  ul,
  ol {
    padding-left: 0;
  }

  ul {
    list-style-type: none;
  }

  ul > li::before {
    background-color: var(--ion-color-dark);
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 0.8em;
    margin-bottom: 0.2em;
    height: 0.4em;
    width: 0.4em;
  }

  ol {
    list-style: none;
    counter-reset: li;

    > li {
      counter-increment: li;
    }
    > li::before {
      font-weight: 600;
      content: counter(li) '.';
      color: var(--ion-color-dark);
      display: inline-block;
      margin-right: 8px;
    }
  }

  a {
    line-height: 2em;
    font-size: 1em;
  }

  a.external::after {
    background: url(~assets/icon/exit.svg);
    width: 1.25em;
    height: 1.25em;
    display: inline-block;
    content: '';
    background-size: cover;
    position: relative;
    top: -0.5em;
  }

  nav {
    ul {
      margin: 0.5em 0 0 0.5em;
    }
  }

  iframe.vimeo {
    width: 100%;
    height: 222px;
  }
}
