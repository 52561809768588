/** Ionic SCSS Variables **/
$fontUnit: 'pt';

/** Ionic CSS Variables **/
:root {
  /** brand colors **/
  --ion-color-brand: var(--ion-1p-teal);
  --ion-color-brand-rgb: 18, 176, 226;
  --ion-color-brand-contrast: #ffffff;
  --ion-color-brand-contrast-rgb: 0, 0, 0;
  --ion-color-brand-shade: #109bc7;
  --ion-color-brand-tint: #2ab8e5;

  --ion-color-brand-dark: #0c285f;
  --ion-color-brand-title-bar: #ffffff;
  --ion-color-header: #e8f6fa;
  --ion-text-color: #1c1c1e;
  --ion-color-text-secondary: #8e8e93;

  // Used for pages. These are the core background colors to be used
  // for ion-content.
  --ion-background-color: var(--ion-color-light);
  --1p-dark-background: var(--ion-1p-darkest);
  --ion-1p-white-background: var(--ion-color-light-tint);

  --ion-1p-darkest: rgb(38, 38, 38);
  --ion-1p-gray: var(--ion-color-medium);
  --ion-1p-faded-gray: #aab5bb;
  --ion-1p-white-gray-lightest: #f2f2f7;
  --ion-1p-gray-background: #e5e5e5;
  --ion-1p-lightest: rgb(244, 244, 244);
  --ion-1p-blue-primary: rgb(0, 169, 206);

  --ion-1p-secondary-font: 'Open Sans', sans-serif;
  --ion-1p-font-dominant: 'Industry', sans-serif;
  --ion-1p-font-size-caption: 0.65rem; /* 10px? */

  --ion-font-family: 'Open Sans', sans-serif;

  --ion-1p-light-blue: #e5f7fd;

  --ion-1p-teal: #12b0e2;
  --ion-1p-orange: rgb(255, 128, 0);
  --ion-1p-green: rgb(50, 184, 87);
  --ion-1p-red: #f43653; //rgb(204, 28, 28);

  --ion-1p-protein-color: #ff9500;
  --ion-1p-protein-color-faded: #fff4e5;
  --ion-1p-carb-color: #34c759;
  --ion-1p-carb-color-faded: #ebf9ee;
  --ion-1p-fat-color: #af52de;
  --ion-1p-fat-color-faded: #ebdff2;
  --ion-1p-calorie-color: #30b0c7;
  --ion-1p-calorie-color-faded: #c4ebf8;
  --ion-1p-fiber-color: var(--ion-color-medium);
  --ion-1p-fiber-color-faded: var(--ion-color-light-shade);
  --ion-1p-water-color: var(--ion-1p-calorie-color);
  --ion-1p-water-color-faded: var(--ion-1p-calorie-color-faded);

  --ion-1p-chip-blue: #09bee5;
  --ion-message-background-color: #88d8f1;

  --ion-1p-camera-overlay: rgba(38, 38, 38, 0.5);

  /** primary **/
  --ion-color-primary: var(--ion-color-organization, var(--ion-color-brand));
  --ion-color-primary-rgb: var(
    --ion-color-organization-rgb,
    var(--ion-color-brand-rgb)
  );
  --ion-color-primary-contrast: var(--ion-color-organization-contrast, #ffffff);
  --ion-color-primary-contrast-rgb: var(
    --ion-color-organization-contrast-rgb,
    255,
    255,
    255
  );
  --ion-color-primary-shade: var(--ion-color-organization-shade, #009dd6);
  --ion-color-primary-tint: var(--ion-color-organization-tint, #0dbeff);

  /** organization **/

  /** secondary **/
  --ion-color-secondary: var(--ion-1p-orange);
  --ion-color-secondary-rgb: 255, 128, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ed7621;
  --ion-color-secondary-tint: #ff8c3b;
  --ion-color-secondary-lightest: #fff2e9;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  --ion-color-tertiary-lightest: #e9e3fb;

  /** success **/
  --ion-color-success: var(--ion-1p-green);
  --ion-color-success-rgb: 50, 184, 87;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2da64f;
  --ion-color-success-tint: #36c960;
  --ion-color-success-lightest: #eaf8ee;

  --ion-color-chip-blue: #09bee5;
  --ion-color-chip-blue-rgb: 9, 190, 229;
  --ion-color-chip-blue-contrast: #000000;
  --ion-color-chip-blue-contrast-rgb: 0, 0, 0;
  --ion-color-chip-blue-shade: #08a7ca;
  --ion-color-chip-blue-tint: #22c5e8;
  --ion-color-chip-blue-lightest: #e8f6fa;

  /** warning **/
  --ion-color-warning: #fdd724;
  --ion-color-warning-rgb: 253, 215, 36;
  --ion-color-warning-contrast: var(--ion-color-dark);
  --ion-color-warning-contrast-rgb: var(--ion-color-dark-rgb);
  --ion-color-warning-shade: #f0ca22;
  --ion-color-warning-tint: #ffdd45;
  --ion-color-warning-lightest: #fff9de;

  /** danger **/
  --ion-color-danger: var(--ion-1p-red);
  --ion-color-danger-rgb: 204, 28, 28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ba1a1a;
  --ion-color-danger-tint: #de1f1f;
  --ion-color-danger-lightest: #fae8e8;

  /** dark **/
  --ion-color-dark: #262626;
  --ion-color-dark-2: #92949c;
  --ion-color-dark-rgb: 38, 38, 38;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #141414;
  --ion-color-dark-tint: #383838;

  /** medium **/
  --ion-color-medium: #b2b2b2;
  --ion-color-medium-rgb: 178, 178, 178;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #a1a1a1;
  --ion-color-medium-tint: #c4c4c4;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: var(--ion-color-dark);
  --ion-color-light-contrast-rgb: var(--ion-color-dark-rgb);
  --ion-color-light-shade: #e3e3e3;
  --ion-color-light-tint: #ffffff;

  /** default **/
  --default-margin: 10px;
  --default-font-size: 1rem;

  /** typography **/
  --app-display-title-size: 60#{$fontUnit};
  --app-display-title-line: (60 * 1.1) #{$fontUnit};
  --app-header-h1-size: 38#{$fontUnit};
  --app-header-h1-line: (38 * 1.1) #{$fontUnit};
  --app-header-h2-size: 32#{$fontUnit};
  --app-header-h2-line: (32 * 1.1) #{$fontUnit};
  --app-header-h3-size: 24#{$fontUnit};
  --app-header-h3-line: (24 * 1.1) #{$fontUnit};
  --app-header-h4-size: 20#{$fontUnit};
  --app-header-h4-line: (20 * 1.1) #{$fontUnit};
  --app-callout-paragraph-size: 20#{$fontUnit};
  --app-callout-paragraph-line: (20 * 1.5) #{$fontUnit};
  --app-paragraph-color: var(--ion-color-dark);
  --app-paragraph-size: 12#{$fontUnit};
  --app-paragraph-line: (12 * 1.5) #{$fontUnit};
  --app-paragraph-weight: 500;
  --app-small-paragraph-size: 11#{$fontUnit};
  --app-small-paragraph-line: (11 * 1.5) #{$fontUnit};
  --app-caption-size: 10#{$fontUnit};
  --app-caption-line: (10 * 1.3) #{$fontUnit};
  --app-button-link-size: 10#{$fontUnit};
  --app-button-link-line: (10 * 1.2) #{$fontUnit};

  /** ion globals **/
  --height: 2.2em;

  --ion-color-light-gray-blue: #a9b5bb;

  --ice-blue: var(--ion-color-light-shade);
  --grey-mid: var(--ion-color-medium-shade);

  //--app-bottom-menu-height: 75px;
  --app-bottom-menu-height: calc(60px + var(--ion-safe-area-bottom));

  --border-radius-block: 8px;
  --border-radius-medium: 4px;
  --border-radius-small: 2px;
  --border-radius-large: 6px;

  --icon-default-outer-fill: var(--ion-color-medium);
  --icon-default-fill: var(--ion-color-light);
}

.ion-color-chip-blue {
  --ion-color-base: var(--ion-color-chip-blue);
  --ion-color-base-rgb: var(--ion-color-chip-blue-rgb);
  --ion-color-contrast: var(--ion-color-chip-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-chip-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-chip-blue-shade);
  --ion-color-tint: var(--ion-color-chip-blue-tint);
}

.ion-color-brand {
  --ion-color-base: var(--ion-color-brand);
  --ion-color-base-rgb: var(--ion-color-brand-rgb);
  --ion-color-contrast: var(--ion-color-brand-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-shade);
  --ion-color-tint: var(--ion-color-brand-tint);
}

.ios,
.md {
  --app-paragraph-size: 13#{$fontUnit};
  --app-paragraph-line: (13 * 1.5) #{$fontUnit};
  --app-small-paragraph-size: 11#{$fontUnit};
  --app-small-paragraph-line: (11 * 1.5) #{$fontUnit};
  --app-caption-size: 10#{$fontUnit};
  --app-caption-line: (10 * 1.3) #{$fontUnit};
  --app-button-link-size: 10#{$fontUnit};
  --app-button-link-line: (10 * 1.2) #{$fontUnit};
}

/*
 * Dark Colors
 * -------------------------------------------
 */

// Note that there are some variables here which have not changed (e.g. --ion-1p-fiber-color) but are
// set _again_ here in this block. This is because CSS variables are scoped. --ion-1p-fiber-color = var(--ion-color-medium)
// and in order to "pick up" the change of the variable --ion-color-medium, this variable MUST BE REDECLARED at the
// same level so that it can see the change of the dependent value.
body .dark {
  --ion-color-primary: var(--ion-color-organization, var(--ion-color-brand));
  --ion-color-primary-rgb: var(
    --ion-color-organization-rgb,
    var(--ion-color-brand-rgb)
  );
  --ion-color-primary-contrast: var(--ion-color-organization-contrast, #ffffff);
  --ion-color-primary-contrast-rgb: var(
    --ion-color-organization-contrast-rgb,
    255,
    255,
    255
  );
  --ion-color-primary-shade: var(--ion-color-organization-shade, #009dd6);
  --ion-color-primary-tint: var(--ion-color-organization-tint, #0dbeff);

  --ion-color-secondary: var(--ion-1p-orange);
  --ion-color-secondary-rgb: 255, 128, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ed7621;
  --ion-color-secondary-tint: #ff8c3b;
  --ion-color-secondary-lightest: #fff2e9;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  --ion-color-tertiary-lightest: #e9e3fb;

  --ion-color-success: var(--ion-1p-green);
  --ion-color-success-rgb: 50, 184, 87;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2da64f;
  --ion-color-success-tint: #36c960;
  --ion-color-success-lightest: #eaf8ee;

  --ion-color-warning: #fdd724;
  --ion-color-warning-rgb: 253, 215, 36;
  --ion-color-warning-contrast: var(--ion-color-dark);
  --ion-color-warning-contrast-rgb: var(--ion-color-dark-rgb);
  --ion-color-warning-shade: #f0ca22;
  --ion-color-warning-tint: #ffdd45;
  --ion-color-warning-lightest: #fff9de;

  --ion-color-danger: var(--ion-1p-red);
  --ion-color-danger-rgb: 204, 28, 28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ba1a1a;
  --ion-color-danger-tint: #de1f1f;
  --ion-color-danger-lightest: #fae8e8;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-background-color: var(--ion-color-light);
  --ion-1p-white-background: var(--ion-color-light-shade);

  --ion-color-brand-title-bar: var(--ion-color-light-shade);

  // These are for the last few "custom" icons we have in a
  --icon-default-outer-fill: var(--ion-color-medium);
  --icon-default-fill: var(--ion-color-light);

  --ion-1p-white-gray-lightest: #202021;

  --ion-1p-teal: #12b0e2;
  --ion-1p-orange: rgb(255, 128, 0);
  --ion-1p-green: rgb(50, 184, 87);
  --ion-1p-red: #f43653; //rgb(204, 28, 28);

  --ion-1p-protein-color: #ff9500;
  --ion-1p-protein-color-faded: #442b04;
  --ion-1p-carb-color: #24b25b;
  --ion-1p-carb-color-faded: #0a411f;
  --ion-1p-fat-color: #af52de;
  --ion-1p-fat-color-faded: #391a4a;
  --ion-1p-calorie-color: #30b0c7;
  --ion-1p-calorie-color-faded: #053544;
  --ion-1p-fiber-color: var(--ion-color-medium);
  --ion-1p-fiber-color-faded: var(--ion-color-light-tint);
  --ion-1p-water-color: var(--ion-1p-calorie-color);
  --ion-1p-water-color-faded: var(--ion-1p-calorie-color-faded);

  --ion-color-brand: var(--ion-1p-teal);
  --ion-color-brand-rgb: 18, 176, 226;
  --ion-color-brand-contrast: #ffffff;
  --ion-color-brand-contrast-rgb: 0, 0, 0;
  --ion-color-brand-shade: #109bc7;
  --ion-color-brand-tint: #2ab8e5;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body .dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

//.ios ion-modal {
//  --ion-background-color: var(--ion-color-step-100);
//  --ion-toolbar-background: var(--ion-color-step-150);
//  --ion-toolbar-border-color: var(--ion-color-step-250);
//}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body .dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}
