// START: Industry

@font-face {
  font-family: 'Industry';
  src: url('../assets/fonts/Industry/Industry-Bold.woff') format('woff');
  font-weight: 800;
}

@font-face {
  font-family: 'Industry';
  src: url('../assets/fonts/Industry/Industry-Ultra.woff2') format('woff2');
  font-weight: 900;
}

// END: Industry

@font-face {
  font-family: 'Open Sans';
  font-weight: 200;
  font-style: normal;
  src: url('../assets/fonts/open-sans/OpenSans-Light-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/open-sans/OpenSans-LightItalic-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400 normal;
  src: url('../assets/fonts/open-sans/OpenSans-Italic-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 normal;
  src: url('../assets/fonts/open-sans/OpenSans-Regular-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/open-sans/OpenSans-Semibold-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/open-sans/OpenSans-SemiboldItalic-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/open-sans/OpenSans-BoldItalic-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/open-sans/OpenSans-Bold-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/open-sans/OpenSans-ExtraBold-webfont.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/open-sans/OpenSans-ExtraBoldItalic-webfont.woff');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: var(--ion-1p-secondary-font), sans-serif;
  font-weight: 700;

  &.semibold {
    font-weight: 500;
  }

  .bold {
    font-weight: 900;
  }
}

h1 {
  font-size: var(--app-header-h1-size);
  line-height: var(--app-header-h1-line);
}

h2 {
  font-size: var(--app-header-h2-size);
  line-height: var(--app-header-h2-line);
}

.spread-out {
  letter-spacing: 0.08em;
}

.uppercase {
  text-transform: uppercase;
}

h3 {
  font-size: var(--app-header-h3-size);
  line-height: var(--app-header-h3-line);
}

h4 {
  font-size: var(--app-header-h4-size);
  line-height: var(--app-header-h4-line);
}

.callout-paragraph {
  font-size: var(--app-callout-paragraph-size);
  line-height: var(--app-callout-paragraph-line);
}

p {
  font-size: var(--app-paragraph-size);
  line-height: var(--app-paragraph-line);
}

// Android typography css starts here

.plt-android {
  --app-display-title-size: 48#{$fontUnit};
  --app-display-title-line: (48 * 1.1) #{$fontUnit};
  --app-header-h1-size: 32#{$fontUnit};
  --app-header-h1-line: (32 * 1.1) #{$fontUnit};
  --app-header-h2-size: 24#{$fontUnit};
  --app-header-h2-line: (24 * 1.1) #{$fontUnit};
  --app-header-h3-size: 16#{$fontUnit};
  --app-header-h3-line: (16 * 1.1) #{$fontUnit};
  --app-paragraph-size: 16#{$fontUnit};
  --app-paragraph-line: (16 * 1.5) #{$fontUnit};
  --app-small-paragraph-size: 14#{$fontUnit};
  --app-small-paragraph-line: (14 * 1.5) #{$fontUnit};
  --app-caption-size: 12#{$fontUnit};
  --app-caption-line: (12 * 1.3) #{$fontUnit};
  --app-button-link-size: 12#{$fontUnit};
  --app-button-link-line: (12 * 1.2) #{$fontUnit};
}

// iOS variable css starts here

.plt-ios {
  --app-display-title-size: 34#{$fontUnit};
  --app-display-title-line: (34 * 1.1) #{$fontUnit};
  --app-header-h1-size: 28#{$fontUnit};
  --app-header-h1-line: (28 * 1.1) #{$fontUnit};
  --app-header-h2-size: 22#{$fontUnit};
  --app-header-h2-line: (22 * 1.1) #{$fontUnit};
  --app-header-h3-size: 20#{$fontUnit};
  --app-header-h3-line: (20 * 1.1) #{$fontUnit};
  --app-header-h4-size: 17#{$fontUnit};
  --app-header-h4-line: (17 * 1.1) #{$fontUnit};
  --app-paragraph-size: 16#{$fontUnit};
  --app-paragraph-line: (16 * 1.5) #{$fontUnit};
  --app-small-paragraph-size: 14#{$fontUnit};
  --app-small-paragraph-line: (14 * 1.5) #{$fontUnit};
  --app-caption-size: 12#{$fontUnit};
  --app-caption-line: (12 * 1.3) #{$fontUnit};
  --app-button-link-size: 12#{$fontUnit};
  --app-button-link-line: (12 * 1.2) #{$fontUnit};
}

/**
 * Font weights for Open Sans. These might work for other fonts, too?
 */
.fw-light {
  font-weight: 200;
}

.fw-normal {
  font-weight: 400;
}

.fw-semibold {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 900;
}

.text-color-base {
  color: var(--ion-color-base, var(--ion-color-text));
}
