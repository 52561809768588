// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import './theme/variables.scss';
@import './theme/typography.scss';
@import './theme/buttons.scss';
@import './theme/utility.scss';
@import './theme/style.scss';
@import './theme/help.scss';
@import './theme/calendar';
@import './theme/popovers';
@import './theme/ion-datetime.scss';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import 'app/modules/video/pages/live-stream/video.scss';

// TODO: Remove these fixes when updating ionic since they shouldn't be needed
@import './theme/action-sheet-fixes.scss';
