.alert-head {
  padding: 16px !important;
}

.alert-radio-label {
  white-space: normal !important;
  font-size: 16px !important;
  padding: 0 5px 0 30px !important;
}

.alert-title {
  font-weight: bold !important;
}
