// For popovers and dialogs...

ion-popover {
  &.leaderboards {
    --width: 80%;

    .popover-viewport.sc-ion-popover-ios {
      min-height: fit-content;
    }

    .popover-arrow {
      &.sc-ion-popover-ios:after {
        left: 4px;
        border-bottom-left-radius: 2px;
      }
    }
  }
}

.meal-template-name-modal {
  --width: 100%;
  --height: 35%;
  --min-height: 225px;
  --max-width: 320px;
  --border-radius: 4px;
}

.body-metric-customize-info {
  .popover-content {
    width: 90%;
  }
}

.small-popup {
  .popover-content {
    bottom: revert;
  }

  // @todo Why is this even necessary?
  .popover-viewport.sc-ion-popover-ios {
    min-height: unset;
  }
}

#nav-menu-expanded {
  height: calc(100% - var(--app-bottom-menu-height, 75px));
  --height: 100%;
  --max-height: 100%;

  &::part(content) {
    --width: 100%;
    --box-shadow: none;
    --border-radius: 0;
  }
}

.global-modal-copy-meals {
  ion-backdrop {
    visibility: visible;
  }

  --border-radius: 6px;
  --left: calc(50% - (320px / 2));
  --top: calc(50% - (360px / 2));
  --position: absolute;
  --width: 320px;
  --height: 360px;
}

.popover-wrapper {
  width: 95%;
  --box-shadow: none;
}
