.fue-modal {
  --background: var(--ion-1p-white-background);
  -webkit-backdrop-filter: blur(5px);
  --backdrop-filter: blur(5px);

  .swiper-zoom-container {
    text-align: flex-start;
  }

  swiper-container {
    width: 100%;
    height: 100%;
  }

  swiper-slide {
    display: flex;
  }

  .buttons {
    display: flex;
    padding: 0 var(--default-margin);
    position: absolute;
    z-index: 101;
    height: 4em;
    width: 100%;
    bottom: var(--ion-safe-area-bottom, 15px);
    left: 0;
    justify-content: flex-end;
  }

  swiper-slide section img {
    max-height: 20vh;
    margin: var(--default-margin) auto;
    display: block;
  }

  swiper-container {
    width: 100%;
    flex: 1;
    --swiper-theme-color: var(--ion-color-primary);
    --swiper-pagination-bullet-inactive-color: var(--ion-color-light-contrast);
  }

  .swiper-slide {
    align-items: flex-start;
  }

  ion-content {
    --background: transparent;
    > section {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
