ion-header {
  //background-color: var(--ion-color-brand-title-bar);

  ion-toolbar {
    // These should default to the org colors, if we have them. Otherwise, they'll fall back to the regular colors.
    --background: var(--ion-color-organization, var(--ion-color-light-tint));
    --ion-toolbar-background: var(
      --ion-color-organization,
      var(--ion-color-light-tint)
    );
    --ion-toolbar-color: var(
      --ion-color-organization-contrast,
      var(--ion-color-dark)
    );

    ion-icon {
      color: var(--ion-color-organization-contrast, var(--ion-color-dark));
      font-size: large;
    }
  }

  ion-title {
    text-transform: uppercase;
    font-family: var(--ion-1p-font-dominant);
    font-weight: 900;
    text-align: center;
    position: absolute;
    top: 0;
    left: 3%;
    width: 95%;
    height: 100%;
  }
}

/**
 * Used in instances where we need small-text inside of a title.
 * This isn't always in the header.
 */
ion-toolbar ion-title > small {
  display: block;
  font-size: 0.65em;
  color: var(--ion-toolbar-color, var(--ion-color-light-contrast));
  font-weight: normal;
  font-family: var(--ion-font-family);
  text-transform: none;
}

.dark {
  ion-header {
    background-color: var(--ion-color-light);

    ion-toolbar {
      --background: var(--ion-color-brand-title-bar);
      --ion-toolbar-background: var(--ion-color-brand-title-bar);
      --ion-toolbar-color: var(--title-bar-color, var(--ion-color-dark));

      ion-icon {
        color: var(--title-bar-color, var(--ion-color-dark));
        font-size: large;
      }
    }
  }
}

.ios ion-header ion-toolbar ion-title {
  padding-inline-end: 10vw;
  padding-inline-start: 10vw;
}
