// Fixes for the non-scrollable ion-select on iOS
// https://github.com/ionic-team/ionic-framework/issues/17311

// workaround: full screen action sheet scrolling background on iOS
.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}
// workaround: action sheet not honoring safe area margins
.action-sheet-wrapper.sc-ion-action-sheet-ios {
  top: 0;
  height: auto;
}

app-leaderboards-popup .list-ios {
  margin-bottom: 0;
}
